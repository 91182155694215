<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('transaction.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      v-if="viewFilter"
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y box col-span-12 lg:col-span-12 p-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <app-action-toolbar
            @onPrint="doPrint()"
            @onExportXlsx="onExportXlsx()"
            @onExportAllXlsx="onExportAllXlsx()"
            @onDownloadPdf="doDownloadPdf()"
          ></app-action-toolbar>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="tablePrint"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap text-center" id="">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="
                      col.name === 'action'
                        ? 'ignore-2'
                        : col.name === 'avatar'
                        ? 'ignore-3'
                        : ''
                    "
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="w-40">
                    <div
                      class="text-gray-600 text-xs whitespace-nowrap mt-0.5 text-center"
                    >
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    >
                      {{ presenterPrice(row.amount) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{
                        row.type
                          ? i18n(`transaction.enumerators.type.${row.type}`)
                          : 'ـــ'
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap flex gap-2 items-center justify-center"
                      :class="getRowClass(row)"
                    >
                      <Icon :icon="getIcon(row)" class="w-6 h-6" />
                      {{ presenterInOut(row) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'objectNo') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row, 'trackId') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="flex justify-center">
                      <Tippy
                        tag="div"
                        class="font-medium whitespace-nowrap truncate w-40"
                        :content="presenterLocalization(row, 'notes')"
                      >
                        {{ presenterLocalization(row, 'notes') }}</Tippy
                      >
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="font-medium whitespace-nowrap flex items-center justify-center"
                      :class="{
                        'text-gray-500': row.status == 'pending',
                        'text-theme-24': row.status == 'fail',
                        'text-green-600 dark:text-theme-30':
                          row.status == 'success'
                      }"
                    >
                      {{
                        row.status
                          ? i18n(`transaction.enumerators.status.${row.status}`)
                          : 'ـــ'
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.transaction')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :hasNext="hasNext"
            :hasPrev="hasPrev"
            :loading="loading"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
            @page-change="goToPage"
          ></Pagination>
        </div>
        <!-- END: HTML Table Data -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionToolbar from '@/global-components/action-toolbar.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'

export default {
  name: 'app-wallet-transaction-table',
  mixins: [paginationMixin, presenterMixin, exportPrintMixin],
  props: {
    userId: {
      type: String,
      required: true
    },
    user: {
      type: String,
      required: true
    },
    viewFilter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    [ActionToolbar.name]: ActionToolbar
  },
  data() {
    return {
      // data important for Mixins
      tableRef: null,
      exportTableName: 'wallets',
      excludedCols: [7],
      ignoreElements: ['ignore-1', 'ignore-2'],
      // component Data
      selectedId: null,
      type: 'in',
      columns: [
        {
          name: 'code',
          field: 'code',
          label: 'common.code',
          align: 'center'
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'transaction.fields.amount',
          align: 'center'
        },
        {
          name: 'type',
          field: 'type',
          label: 'transaction.fields.type',
          align: 'center'
        },
        {
          name: 'inOut',
          field: 'inOut',
          label: 'transaction.fields.inOut',
          align: 'center'
        },
        {
          name: 'orderNumber',
          field: 'orderNumber',
          label: 'order.fields.orderNumber',
          align: 'center'
        },
        {
          name: 'trackId',
          field: 'trackId',
          label: 'transaction.fields.trackId',
          align: 'center'
        },
        {
          name: 'notes',
          field: 'notes',
          label: 'transaction.fields.notes',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'transaction.fields.status',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'transaction.fields.createdAt',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'transaction/list/rows',
      hasNext: 'transaction/list/hasNext',
      hasPrev: 'transaction/list/hasPrev',
      pagination: 'transaction/list/pagination',
      listLoading: 'transaction/list/loading',
      exportLoading: 'exportStore/loading'
    }),
    fetchOptions() {
      return { walletId: this.userId }
    },
    exportResourceName() {
      return `wallet-${this.user}`
    },
    loading() {
      return this.listLoading || this.exportLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    transactionOptions() {
      return [
        {
          label: this.i18n('transaction.enumerators.type.recharge'),
          value: 'recharge',
          operator: 'equal'
        },
        {
          label: this.i18n('transaction.enumerators.type.withdraw'),
          value: 'withdraw',
          operator: 'equal'
        },
        {
          label: this.i18n('transaction.enumerators.type.refund'),
          value: 'refund',
          operator: 'equal'
        },
        {
          label: this.i18n('transaction.enumerators.type.order'),
          value: 'order',
          operator: 'equal'
        },
        {
          label: this.i18n('transaction.enumerators.type.promotion'),
          value: 'promotion',
          operator: 'equal'
        }
      ]
    },
    inOutOptions() {
      return [
        {
          label: this.isRTL ? 'دائن' : 'In',
          value: '-1',
          operator: 'notEqual'
        },
        {
          label: this.isRTL ? 'مدين' : 'Out',
          value: '-1',
          operator: 'equal'
        }
      ]
    },
    statusOptions() {
      return [
        {
          label: this.i18n('transaction.enumerators.status.pending'),
          value: 'pending'
        },
        {
          label: this.i18n('transaction.enumerators.status.success'),
          value: 'success'
        },
        {
          label: this.i18n('transaction.enumerators.status.fail'),
          value: 'fail'
        }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('transaction.fields.amount'),
          value: 'amount',
          type: 'number-range'
        },
        {
          label: this.i18n('transaction.fields.type'),
          value: 'type',
          type: 'listWithOperator',
          options: this.transactionOptions
        },
        {
          label: this.i18n('transaction.fields.inOut'),
          value: 'payeeId',
          type: 'listWithOperator',
          options: this.inOutOptions
        },
        {
          label: this.i18n('order.fields.orderNumber'),
          value: 'objectNo',
          type: 'text'
        },
        {
          label: this.i18n('transaction.fields.trackId'),
          value: 'trackId',
          type: 'text'
        },
        {
          label: this.i18n('transaction.fields.status'),
          value: 'status',
          type: 'list',
          options: this.statusOptions
        },
        {
          label: this.i18n('transaction.fields.createdAtRange'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'transaction/list/doFetch',
      doFetchNextPage: 'transaction/list/doFetchNextPage',
      doFetchPreviousPage: 'transaction/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'transaction/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'transaction/list/doChangePaginationPageSize',
      doExport: 'exportStore/doExport'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    getRowClass(row) {
      const type = this.presenterInOut(row)
      return {
        'text-theme-35': this.isDebtor(type),
        'text-theme-10': this.isCreditor(type)
      }
    },
    isDebtor(type) {
      return ['مدين', 'Out'].includes(type)
    },
    isCreditor(type) {
      return ['دائن', 'In'].includes(type)
    },
    getIcon(row) {
      return this.isDebtor(this.presenterInOut(row)) ? 'ei:minus' : 'ei:plus'
    },
    presenterInOut(row) {
      return row.payeeId === this.userId
        ? this.isRTL
          ? 'مدين'
          : 'Out'
        : this.isRTL
        ? 'دائن'
        : 'In'
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
