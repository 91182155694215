<template>
  <section class="w-full">
    <!-- zoom-in -->
    <div class="w-full card-body">
      <div class="content-section w-full">
        <div class="flex items-center gap-5">
          <img :src="icon" alt="" size="3.5rem" class="icon" v-if="icon">
          <div>
            <div>
              <app-i18n :code="label" class="title"></app-i18n>
            </div>
            <div class="amount whitespace-wrap" :style="amountStyle">{{ amountValue }}</div>
          </div>
        </div>
      </div>
      <div class="available-balance flex justify-end" v-if="availableBalance">
        <div>
          <app-i18n code="Available"></app-i18n> :
          <span class="balance"> {{ availableBalanceValue }}</span>
        </div>
      </div>
      <div class="card-actions">
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  name: 'app-wallet-card',
  props: {
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    availableBalance: {
      type: Number,
      required: false
    },
    'amount-style': {
      type: String,
      default: 'color: #000000 !important;'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    amountValue() {
      const PRICE = this.roundNumber(this.amount)
      const amount = this.numberWithCommas(PRICE)
      return this.language == 'ar'
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    },
    availableBalanceValue() {
      const PRICE = this.roundNumber(this.availableBalance)
      const amount = this.numberWithCommas(PRICE)
      return this.language == 'ar'
        ? `${amount || 0} ريال سعودي`
        : `${amount || 0} SAR`
    }
    // amountColor() {
    //   return this.value.type == 'credit'
    //     ? { color: '#23C045' }
    //     : { color: '#F14336' }
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar'
        ? `${PRICE} ريال`
        : `${PRICE} SAR`
    },
    roundNumber(number) {
      return (Math.round(number * 100) / 100).toFixed(2)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
<style lang="scss" scoped>
.card-body {
  background: #FFFFFF !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-bottom: 1rem !important;
}

.content-section {
  padding: 20px !important;
}

.card-actions {
  background: #f8f8f8 !important;
  padding: 1.5rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  button {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: underline !important;
    color: #515151 !important;
  }
}

.available-balance {
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;

  .balance {
    color: #13B177 !important;
    font-weight: 600 !important;
  }
}

.title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.9375rem !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-right: 0.5rem !important;
}

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}

</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .q-card {
    // min-height: calc(80px + (77 - 80) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(6px + (15 - 6) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .content-section {
    padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .card-actions {
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .avatar {
    margin-right: calc(8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .amount {
    font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .details-label {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
    line-height: calc(18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))) !important;
  }

  .details-value {
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1440 - 320))) !important;
    line-height: 18px;
  }
}
</style>
